@font-face {
    font-family: "Neo Sans W01";
    src: url("Fonts/a53085dd-4493-4430-8349-fd63d6f3e5bc.woff2") format("woff2"), url("Fonts/a32c3a17-3242-461c-a048-ca8e775582b1.woff") format("woff");
}

@font-face {
    font-family: "Neo Sans W01 Medium";
    src: url("Fonts/f056e9e7-3bb5-4f21-b861-ed2a47a0700b.woff2") format("woff2"), url("Fonts/b0eddbbb-9e8e-4e08-bde1-965bb945f73a.woff") format("woff");
}

@font-face {
    font-family: "Neo Sans W01 Bold";
    src: url("Fonts/82a34ab2-92d9-48c4-989d-50d5bd54fdd1.woff2") format("woff2"), url("Fonts/ecedcc38-3aec-48a5-a693-fbfac59dd0ed.woff") format("woff");
}